import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TitleService {
  public constructor(
    private _translateService: TranslateService,
    private _titleService: Title,
  ) {}

  public subscription: Subscription;

  private _translateTitle(newTitle: string, params?: any) {
    this._titleService.setTitle(
      this._translateService.instant(newTitle, params),
    );
  }

  public setTitle(newTitle: string, params?: any) {
    this._translateTitle(newTitle, params);
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.subscription = this._translateService.onLangChange.subscribe((_) => {
      this._translateTitle(newTitle, params);
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
